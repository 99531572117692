import React, {useEffect, useRef, useState} from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRange} from 'react-date-range';
import {TextField} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import * as rdrLocales from 'react-date-range/dist/locale';
import useOutsideClick from "../../../hooks/useOutsideClick";
import PropTypes from "prop-types";
import useScreenSize from "../../../hooks/useScreenSize";
import {DATE_PATTERN, DATE_RANGE_PATTERN} from "../../../utils/dateUtils";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: 14
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 125,
        textAlign: 'center'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        minWidth: 150,
        fontSize: 14
    },
    input: {
    },
    errorInput: {
        color: "red"
    }
}));

DateRangePicker.propTypes = {
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    selectFromDateFunction: PropTypes.func,
    selectToDateFunction: PropTypes.func,
    onClosePicker: PropTypes.func,
    hidden: PropTypes.bool,
    label: PropTypes.string,
    inputType: PropTypes.oneOf(['materialUi', 'simple']),
    disabled: PropTypes.bool,
    locatedInRightFilterBlock: PropTypes.bool,

};


export default function DateRangePicker(props) {
    const {fromDate, toDate, selectFromDateFunction, selectToDateFunction, onClosePicker, hidden, label, inputType='materialUi', disabled, locatedInRightFilterBlock, inputStyle} = props;
    const classes = useStyles();

    const [isDisplayed, setDisplayed] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [value, setValue] = useState("");
    const [hasError, setHasError] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const match = value?.match(DATE_RANGE_PATTERN);
        let startDate = formatDate(fromDate);

        let endDate = formatDate(toDate);

        if (match) {
            const existedFromDateStr = `${match[1]}.${match[2]}.${match[3]}`;
            const existedFromDate = parseDate(existedFromDateStr);
            if (existedFromDate.getTime() === fromDate.getTime()) {
                startDate = existedFromDateStr;
            }

            const existedToDateStr = `${match[5]}.${match[6]}.${match[7]}`;
            const existedToDate = parseDate(existedToDateStr);
            if (existedToDate.getTime() === toDate.getTime()) {
                endDate = existedToDateStr;
            }
        }

        setValue(startDate + " - " + endDate);
    }, [fromDate, toDate]);

    const screenSize = useScreenSize();
    const isSmallWidth = screenSize && screenSize.width < 700;


    const handleTextTyping = (e) => {
        const value = e.target.value;
        setValue(value);
        const match = value.match(DATE_RANGE_PATTERN);
        if (match) {
            const from = parseDate(`${match[1]}.${match[2]}.${match[3]}`);
            const to = parseDate(`${match[5]}.${match[6]}.${match[7]}`);
            if (from > to) {
                setHasError(true);
                return;
            }
            selectFromDateFunction && selectFromDateFunction(from);
            selectToDateFunction && selectToDateFunction(to);
            setHasError(false);
            setHasChanges(true);
        } else {
            setHasError(true);
        }
    };


    function handleSelect(ranges) {
        setHasChanges(true);
        let startDate = ranges.selection?.startDate;
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
        let endDate = ranges.selection?.endDate;
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0);
        if (selectFromDateFunction) {
            selectFromDateFunction(startDate);
        }
        if (selectToDateFunction) {
            selectToDateFunction(endDate);
        }
        setValue(formatDate(startDate) + " - " + formatDate(endDate));
    }

    const showOrHide = () => {
        const displayed = !isDisplayed;
        setDisplayed(displayed);
        if (onClosePicker && hasChanges && fromDate && toDate && displayed === false) {
            onClosePicker();
            setHasChanges(false);
        }
    };

    const hide = () => {
        setDisplayed(false);
        if (onClosePicker && hasChanges && fromDate && toDate) {
            onClosePicker();
            setHasChanges(false);
        }
    };


    useOutsideClick(ref, hide);

    const selectionRange = {
        startDate: fromDate,
        endDate: toDate,
        key: 'selection',
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) { //Enter
            hide();
        }
    };

    let datePickerTop, datePickerRight = null;
    const el = document.getElementById("datePicker");
    const elCoordinates = el?.getBoundingClientRect();
    if (locatedInRightFilterBlock) {
        datePickerTop = elCoordinates?.bottom;
        datePickerRight = elCoordinates && (window.innerWidth - elCoordinates.right + 15);
    }


    const isTextfieldHidden = elCoordinates && (window.innerWidth - elCoordinates.left) < 0;
    if (isTextfieldHidden) {
        isDisplayed && !hidden && hide();
    }

    const simpleInput = () => {
        return <input style={{float: 'none', margin: '10px 0'}} type="text" placeholder="дд.мм.гггг" className={hasError ? "form-control input-error" : "form-control"}
                      value={value} onChange={handleTextTyping} disabled={disabled}/>

    };

    const materialUiInput = () => {
        return <TextField
            id="standard-name"
            label={label}
            className={classes.textField}
            value={value}
            margin="normal"
            style={inputStyle}
            InputProps={{readOnly: !!isSmallWidth,
                className: hasError ? classes.errorInput : classes.input}}
            onChange={handleTextTyping}
        />
    };

    const input = () => {
        switch (inputType) {
            case "simple":
                return simpleInput();
            case "materialUi":
                return materialUiInput();
            default:
                return materialUiInput();

        }
    };


    return (
        <>
            <div ref={ref} onKeyDown={onKeyDown} >
                <div onClick={() => showOrHide()} id="datePicker">
                    {input()}
                </div>
                <div className="datePickerCalendarWrapper" hidden={!isDisplayed || hidden}
                     style={{top: datePickerTop + "px", right: datePickerRight + "px"}}>
                    <DateRange
                        locale={rdrLocales.ru}
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        direction={isSmallWidth ? 'vertical' : 'horizontal'}
                        rangeColors={['#17a2b8']}
                        showDateDisplay={false}
                        maxDate={new Date()}
                        months={isSmallWidth ? 1 : 2}
                    />
                </div>
            </div>
        </>
    );
}

const formatDate = (date) => {
    if (!date) {
        return "дд.мм.гг";
    }
    return date
        ? ('0' + date.getDate()).slice(-2)
        + "."
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + "." + date.getFullYear().toString().substr(-2)
        : "";
};

const parseDate = (dateString) => {
    const match = dateString?.match(DATE_PATTERN);
    if (!match) {
        return;
    }
    return new Date("20" + parseInt(`${match[3]}`), (parseInt(`${match[2]}`) - 1), parseInt(`${match[1]}`));
};
