import React, {useEffect, useState} from "react";
import {
    LIGHT_THEME,
    PNS_BILLING,
    PNS_BUYOUT,
    PNS_SUBSCRIBE,
    PNS_UNSUBSCRIBE,
    POSTBACK_ACTIVE,
    POSTBACK_DONE,
    POSTBACK_NEW,
    POSTBACK_NO_DATA, SUBSCRIPTION_SERVICE_GROUPS, TODAY,
} from "../../../../utils/constants";
import SimpleTable from "../../../common/tables/SimpleTable";
import useThemeChange from "../../../../hooks/useThemeChange";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";
import ActionPopover from "../../../common/popovers/ActionPopover";
import {connect, useSelector} from "react-redux";
import {
    loadPartnerRules,
    resendPostbacks,
    loadPostbackResendingItems,
    deleteResendingItem
} from "../../../../actions/postbackResendingActions";
import {fromDate, getIsoLocalString, getRusDateHourString, getRusDateString, toDate} from "../../../../utils/dateUtils";
import LinearProgressWithLabel from "../../../common/LinearProgressWithLabel";
import DateRangePicker from "../../../common/pickers/DateRangePicker";
import {loadFiltersData} from "../../../../actions/filterDataActions";
import ServiceGroupsFilter from "../../statistic/filters/ServiceGroupsFilter";

const eventTypes = [
    {value: PNS_BUYOUT, label: "Выкупы"},
    {value: PNS_UNSUBSCRIBE, label: "Отписки"},
    {value: PNS_BILLING, label: "Платежи"},
    {value: PNS_SUBSCRIBE, label: "Подписки"},
];

const statusMapping = {
    [POSTBACK_NEW]: "Создано",
    [POSTBACK_ACTIVE]: "Отправка",
    [POSTBACK_DONE]: "Завершено",
    [POSTBACK_NO_DATA]: "Нет данных для отправки",
};


function PostbackResendingComponent({loadPartnerRules, loadPostbackResendingItems, resendPostbacks, deleteResendingItem, partnerRules, resendingItems, loadFiltersData, subscriptionServiceGroups}) {

    const [dropDownEventTypes, setDropdownEventTypes] = useState([]);
    const [selectedEventTypes, setSelectedEventTypes] = useState([]);
    const [selectedRules, setSelectedRules] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState(fromDate(TODAY));
    const [selectedToDate, setSelectedToDate] = useState(toDate(TODAY));
    const [selectedServiceGroups, setSelectedServiceGroups] = useState([]);
    const [selectedServiceIds, setSelectedServiceIds] = useState([]);
    const [rules, setRules] = useState([]);
    const [tableData, setTableData] = useState(null);
    const [count, setCount] = useState(0);

    useEffect(() => {
        loadPartnerRules();
        loadPostbackResendingItems();
        loadFiltersData([SUBSCRIPTION_SERVICE_GROUPS]);
    }, []);

    useEffect(() => {
        if (!resendingItems) {
            return;
        }
        setTableData(resendingItems);
    }, [resendingItems]);


    useEffect(() => {
        if (partnerRules) {
            setRules(partnerRules);
        }
    }, [partnerRules]);

    useEffect(() => {
        if (!selectedRules || selectedRules.length === 0) {
            setDropdownEventTypes([...eventTypes]);
            return;
        }
        const ruleEventTypes = selectedRules.flatMap(rule => rule.eventTypes);
        const filteredDropdownEventTypes = eventTypes.filter(eventType => ruleEventTypes.includes(eventType.value));
        setDropdownEventTypes(filteredDropdownEventTypes);

    }, [selectedRules]);

    useEffect(() => {
        if (!selectedEventTypes || selectedEventTypes.length === 0) {
            return;
        }
        const filteredEventTypes = selectedEventTypes.filter(eventType => dropDownEventTypes.includes(eventType));
        setSelectedEventTypes(filteredEventTypes);
    }, [dropDownEventTypes]);

    useEffect(() => {
        setTimeout(() => {
            try {
                checkStatuses();
                setCount(count + 1);
            } catch (e) {
                console.log(e);
            }

        }, 15000)
    }, [count]);

    useEffect(() => {
        let serviceIds = selectedServiceGroups?.filter(serviceGroup => serviceGroup.isGroup)
            .map(group => group.id)
            .flatMap(groupId => subscriptionServiceGroups.filter(group => group.id===groupId).flatMap(group => group.subscriptionServices.map(service => service.externalId)));
        serviceIds = [...serviceIds, ...selectedServiceGroups?.filter(serviceGroup => !serviceGroup.isGroup).map(service => service.externalId)];
        setSelectedServiceIds(serviceIds);
    }, [selectedServiceGroups]);


    const resend = () => {
        const eventTypes = selectedEventTypes.map(item => item.value);
        const body = {
            fromDate: getIsoLocalString(selectedFromDate),
            toDate: getIsoLocalString(selectedToDate),
            eventsToReport: eventTypes,
            ruleIds: selectedRules.flatMap(rule => rule.ruleIds),
            serviceIds: selectedServiceIds,
        };
        resendPostbacks(body, () => afterResendCallback());
    };

    const afterResendCallback = () => {
        loadPostbackResendingItems();
        clearInputs();
    };

    const clearInputs = () => {
        setDropdownEventTypes([]);
        setSelectedEventTypes([]);
        setSelectedRules([]);
        setSelectedToDate(toDate(TODAY));
        setSelectedFromDate(fromDate(TODAY));
        setSelectedServiceGroups([]);
    };

    const deleteItem = (item) => {
        deleteResendingItem(item?.id, () => loadPostbackResendingItems());
    };

    const reloadItem = (item) => {
        loadPostbackResendingItems([item?.id], (items) => replaceItemsFromTableData(items));
    };

    const replaceItemsFromTableData = (items) => {
        if (!items || !tableData) {
            return;
        }
        const newTableData = tableData.map(tableItem => {
            const item = items.find(item => tableItem.id === item.id);
            return item ? item : tableItem;
        });
        setTableData(newTableData);
    };

    const checkStatuses = () => {
        if (!tableData) {
            return;
        }
        const ids = tableData.filter(item => [POSTBACK_NEW, POSTBACK_ACTIVE].includes(item.status)).map(item => item.id);
        if (!ids || ids.length === 0) {
            return;
        }
        loadPostbackResendingItems(ids, (items) => replaceItemsFromTableData(items));
    };

    const themeName = useThemeChange();

    const inputStyles = {
        border: themeName === LIGHT_THEME ? "1px solid #ced4da" : "1px solid #000",
        borderRadius: ".25rem",
        fontSize: '12px !important',
        padding: "0 0 0 5px",
    };

    const multiSelectStyles = {
        root: {
            padding: "0",
            minWidth: 150,
            underlineBorderBottom: '0px solid rgba(0,0,0,0) !important',
        },
        input: {...inputStyles,
            backgroundColor: themeName === LIGHT_THEME ? "white" : "#1c1c1e",
            minHeight: "30px",

        },
        placeholder: {
            fontSize: "12px !important",
            padding: "0 0 3px 5px",
        },
        paper: {
            maxHeight: 300
        }
    };

    const tableColumns = () => {
        return [
            {
                name: "Время запуска",
                key: "createDateTime",
                getBodyCellValue: item => item["createDateTime"] && getRusDateHourString(new Date(item["createDateTime"]), 2),
                headerCellStyle: {minWidth: "100px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Время завершения",
                key: "updateDateTime",
                getBodyCellValue: item => item["status"] === POSTBACK_DONE && item["updateDateTime"] && getRusDateHourString(new Date(item["updateDateTime"]), 2),
                headerCellStyle: {minWidth: "100px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Партнёр",
                key: "partnerName",
                getBodyCellValue: item => item["partnerName"],
                headerCellStyle: {minWidth: "250px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Период",
                key: "period",
                getBodyCellValue: item => item["fromDate"] && item["toDate"] && getRusDateString(new Date(item["fromDate"]), 2) + " - " + getRusDateString(new Date(item["toDate"]), 2),
                headerCellStyle: {minWidth: "170px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Тип",
                key: "eventType",
                getBodyCellValue: item => <>{item?.eventTypes?.map(itemEventType => {
                    const event = eventTypes.find(eventType => eventType.value === itemEventType).label;
                    const resendingEvent = item.resendingEvents?.find(resendingEvent => itemEventType === resendingEvent.eventType);
                   return  !resendingEvent
                       ? <div key={event}>{event}</div>
                       : resendingEvent["sentPercent"] >= 100
                        ? <div key={event}>{event} ({resendingEvent["totalAmount"]}шт)</div>
                        : <div key={event} >
                            <LinearProgressWithLabel variant="determinate"
                                                     value={resendingEvent["sentPercent"]}
                                                     label={event + " (" + resendingEvent["totalAmount"] + "шт)"} error={resendingEvent.error}/>
                          </div>})}
                    </>,
                headerCellStyle: {minWidth: "250px"},
                bodyCellStyle: {textAlign: "left"},
            },
            {
                name: "Сервисы",
                key: "serviceIds",
                getBodyCellValue: item => item["serviceIds"] && item["serviceIds"].join(", "),
                headerCellStyle: {minWidth: "250px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Статус",
                key: "status",
                getBodyCellValue: item => statusMapping[item["status"]],
                headerCellStyle: {minWidth: "120px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "",
                key: "actions",
                getBodyCellValue: (item) => <div style={{display: "inline-flex"}}>
                    {[POSTBACK_NEW, POSTBACK_ACTIVE].includes(item["status"]) && <i className="ti ti-reload td-save" onClick={() => reloadItem(item)}
                                                                                    title="Обновить"/>}
                    {[POSTBACK_NO_DATA].includes(item["status"]) && <i className="ti ti-trash td-save" onClick={() => deleteItem(item)}
                       title="Удалить"/>}
                </div>,
                headerCellStyle: {minWidth: "160px", width: "160px"},
                bodyCellStyle: {textAlign: "right"},
                isSortingDisabled: true,
            },
        ]
    };

    const tableActionRow = () => {
        return [
            {
                key: "createDateTime",
                getActionCellValue: () => <></>
            },
            {
                key: "updateDateTime",
                getActionCellValue: () => <></>
            },
            {
                key: "partnerName",
                getActionCellValue: () =>
                    <MaterialUIMultiSelect styles={multiSelectStyles}
                                           dropdownData={rules}
                                           values={selectedRules}
                                           onSelect={setSelectedRules}
                                           dataField='partnerName'
                                           displayedField='partnerName'
                                           isSearchable
                                           isClearable={true}
                                           maxChipTxtWidth={220}
                                           placeholder="-"
                    />
            },
            {
                key: "period",
                getActionCellValue: () => <DateRangePicker fromDate={selectedFromDate}
                                                           toDate={selectedToDate}
                                                           selectFromDateFunction={setSelectedFromDate}
                                                           selectToDateFunction={setSelectedToDate}
                                                           inputType="simple"
                                                           />
            },
            {
                key: "eventType",
                getActionCellValue: () =>
                    <MaterialUIMultiSelect styles={multiSelectStyles}
                                           dropdownData={dropDownEventTypes}
                                           values={selectedEventTypes}
                                           onSelect={setSelectedEventTypes}
                                           dataField='value'
                                           displayedField='label'
                                           isSearchable
                                           isClearable={true}
                                           maxChipTxtWidth={220}
                                           placeholder="-"
                    />
            },
            {
                key: "serviceIds",
                getActionCellValue: () => <ServiceGroupsFilter inputStyle={multiSelectStyles}
                                                               handleChangeFilterData={(type, value) => setSelectedServiceGroups(value)}
                                                               selectedServiceGroups={selectedServiceGroups}
                                                               label=''/>
            },
            {
                key: "status",
                getActionCellValue: () => <></>
            },
            {
                key: "actions",
                getActionCellValue: () => <div style={{display: "inline-flex"}}>
                    <ActionPopover
                        button={
                            <i className="ti ti-control-play td-save"/>
                        }
                        tooltip='Запустить'
                        popoverLocation='right'
                        label='Запустить переотправку постбеков?'
                        handleSubmit={resend}
                    />
                    <i className="ti ti-close td-save" onClick={clearInputs} title='Очистить'/>
                </div>
            },

        ]
    };

    return <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={() =>{}} style={{height: '100%'}}>
            <SimpleTable tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext" columns={tableColumns()}
                         data={tableData} bodyRowKey="id" actionRow={tableActionRow()}/>
        </div>

}

const mapStateToProps = state => ({
    partnerRules: state.postbackResendingReducer.partnerRules,
    resendingItems: state.postbackResendingReducer.resendingItems,
    subscriptionServiceGroups: state.filterDataReducer.filterData?.subscriptionServiceGroups,
});

export default connect(mapStateToProps, {
    loadPartnerRules,
    resendPostbacks,
    loadPostbackResendingItems,
    deleteResendingItem,
    loadFiltersData,
})(PostbackResendingComponent);
