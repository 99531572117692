import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON } from "../../../actions/actionTypes";
import { setToLocalStorage } from "../../../utils/localStorageUtils";
import { LOCAL_STORAGE_IS_FILTERS_OPEN } from "../../../utils/constants";
import classNames from "classnames";

const STORAGE_PERIOD_IN_HOURS = 365 * 24;

export default function ToggleFiltersButton() {
    const dispatch = useDispatch();
    const isFilterOpenByMouseMove = useSelector(state => state.navigationReducer.isFilterBlockOpenByMouse);
    const isFilterOpenByBtn = useSelector(state => state.navigationReducer.isFilterBlockOpenByBtn);
    const isOpen = isFilterOpenByMouseMove || isFilterOpenByBtn;
    const btnClass = classNames("filters-btn", { active: isOpen });


    const toggleFiltersBtn = () => {
        dispatch({ type: TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON, payload: !isOpen });
        const localStorageData = { value: !isOpen };
        setToLocalStorage(LOCAL_STORAGE_IS_FILTERS_OPEN, localStorageData, STORAGE_PERIOD_IN_HOURS);
    };

    return (
        <div className={btnClass} onClick={toggleFiltersBtn} title="Фильтры">
            {isOpen ? (

                <svg width="50" height="50" viewBox="100 50 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M433.333 130H613.333C632.003 130 641.363 130 648.493 133.633C654.763 136.829 659.827 141.925 663.02 148.197C666.653 155.327 666.667 164.668 666.667 183.337V208.16C666.667 216.148 666.667 220.184 665.757 223.981C664.94 227.382 663.573 230.631 661.747 233.613C659.687 236.977 656.81 239.86 651.047 245.625L600 296.671M249.987 130H186.654C167.985 130 158.66 130 151.53 133.633C145.258 136.829 140.163 141.925 136.967 148.197C133.334 155.327 133.333 164.668 133.333 183.337V207.912C133.333 216.065 133.333 220.144 134.254 223.981C135.071 227.382 136.421 230.631 138.249 233.613C140.309 236.976 143.197 239.864 148.958 245.625L317.716 414.383C323.481 420.147 326.348 423.013 328.409 426.38C330.237 429.36 331.605 432.62 332.422 436.023C333.333 439.82 333.333 443.85 333.333 451.84V610.367C333.333 638.94 333.333 653.237 339.35 661.84C344.607 669.353 352.713 674.367 361.783 675.707C372.17 677.24 384.957 670.857 410.513 658.08L437.18 644.747C447.883 639.397 453.22 636.71 457.13 632.717C460.587 629.187 463.233 624.95 464.843 620.277C466.667 614.997 466.667 609 466.667 597.033V452.087C466.667 443.933 466.667 439.86 467.587 436.023C468.403 432.62 469.753 429.363 471.583 426.383C473.637 423.03 476.507 420.16 482.23 414.437L482.293 414.38L516.67 380.003M516.67 380.003L166.667 30M516.67 380.003L633.333 496.667"
                        stroke="black" strokeWidth="35" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            ) : (
                <svg width="50" height="50" viewBox="100 50 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M666.667 180.371C666.667 161.703 666.653 152.362 663.02 145.231C659.827 138.959 654.743 133.864 648.473 130.668C641.343 127.035 631.99 127.035 613.32 127.035H186.654C167.985 127.035 158.66 127.035 151.53 130.668C145.258 133.864 140.162 138.959 136.966 145.231C133.333 152.362 133.333 161.703 133.333 180.371V204.947C133.333 213.1 133.333 217.179 134.254 221.015C135.071 224.417 136.421 227.666 138.249 230.648C140.309 234.011 143.197 236.898 148.958 242.66L317.716 411.418C323.481 417.181 326.348 420.048 328.409 423.415C330.237 426.395 331.605 429.655 332.422 433.058C333.333 436.855 333.333 440.885 333.333 448.875V607.401C333.333 635.975 333.333 650.271 339.35 658.875C344.607 666.388 352.713 671.401 361.783 672.741C372.17 674.275 384.957 667.891 410.513 655.115L437.18 641.781C447.883 636.431 453.22 633.745 457.13 629.751C460.587 626.221 463.233 621.985 464.843 617.315C466.667 612.031 466.667 606.035 466.667 594.068V449.121C466.667 440.968 466.667 436.895 467.587 433.058C468.403 429.655 469.753 426.395 471.583 423.415C473.63 420.071 476.49 417.211 482.177 411.525L482.293 411.418L651.05 242.66C656.813 236.895 659.68 234.012 661.743 230.648C663.57 227.666 664.94 224.417 665.757 221.015C666.667 217.218 666.667 213.183 666.667 205.195V180.371Z"
                        stroke="black" strokeWidth="35" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

            )}
        </div>
    );
}
