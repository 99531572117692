import React, {useEffect, useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import Spinner from "../../../common/Spinner";
import {connect} from "react-redux";
import {returnErrors} from "../../../../actions/errorActions";
import LineChart from "../../../common/charts/LineChart";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {
    BUYOUTS,
    BUYOUTS_TREND,
    CLICKS,
    CLICKS_TREND,
    CVR,
    CVR_TREND,
    INCOME_MINUS_OPERATORS_REVENUE,
    INCOME_MINUS_OPERATORS_REVENUE_TREND,
    MARGIN,
    MARGIN_TREND,
    PERCENTAGE_OF_TRAFFICBACKS, PERCENTAGE_OF_TRAFFICBACKS_TREND,
    PERCENTAGE_OF_UNSUBSCRIBES,
    PERCENTAGE_OF_UNSUBSCRIBES_TREND,
    SUBSCRIPTIONS,
    SUBSCRIPTIONS_TREND,
    TRAFFICBACKS,
    TRAFFICBACKS_TREND,
    UNSUBSCRIBES,
    UNSUBSCRIBES_TREND
} from "../../../../utils/constants";

const dropdownData = [
    {name: "Клики", value: [CLICKS, CLICKS_TREND]},
    {name: "Trafficback", value: [TRAFFICBACKS, TRAFFICBACKS_TREND]},
    {name: "% Trafficback", value: [PERCENTAGE_OF_TRAFFICBACKS, PERCENTAGE_OF_TRAFFICBACKS_TREND]},
    {name: "Подписки", value: [SUBSCRIPTIONS, SUBSCRIPTIONS_TREND]},
    {name: "Выкупы", value: [BUYOUTS, BUYOUTS_TREND]},
    {name: "Отписки", value: [UNSUBSCRIBES, UNSUBSCRIBES_TREND]},
    {name: "CR", value: [CVR, CVR_TREND]},
    {name: "% отписок", value: [PERCENTAGE_OF_UNSUBSCRIBES, PERCENTAGE_OF_UNSUBSCRIBES_TREND]},
    {name: "Выручка", value: [INCOME_MINUS_OPERATORS_REVENUE, INCOME_MINUS_OPERATORS_REVENUE_TREND]},
    {name: "Margin", value: [MARGIN, MARGIN_TREND]},
];

function MonitorChartModal(props) {
 const {isLoading, chartData, isOpen, toggleModalOpen, displayedLines, showGroupLineChart} = props;
    const [selectedChartLines, setSelectedChartLines] = useState(null);
    useEffect(() => {
        if (!displayedLines) {
            setSelectedChartLines(dropdownData[0]);
        } else {
            setSelectedChartLines(dropdownData.find(item => item.value.some(line => displayedLines.includes(line))));
        }
    }, []);

    const lines = selectedChartLines && [...selectedChartLines.value];
    return (
        <Modal isOpen={isOpen} toggle={toggleModalOpen} style={{display: "block", paddingRight: "10px", minWidth: "80vw"}}>
            <ModalBody >
                    {isLoading
                        ? <div><Spinner/></div>
                        : <div className="row">
                            <div className="col-12">
                                <MaterialUISelect
                                    styles={{root: {minWidth: 150}}}
                                    dropdownData={[...dropdownData]}
                                    displayedField='name' dataField='value'
                                    placeholder="Тип выплаты"
                                    selectedValue={selectedChartLines}
                                    onSelectCallbackFunction={setSelectedChartLines}
                                    isSearchable={true}/></div>
                            <div className="padding15"></div>
                            <div className="col-12">
                                <LineChart {...chartData} isLoading={isLoading} displayedLines={lines} height={400} showGroupLineChart/>
                            </div>
                        </div>
                    }

            </ModalBody>
        </Modal>
    )
}

export default connect(null,
    {
        returnErrors,
    })(MonitorChartModal);